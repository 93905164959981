import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Layout from '../page/layout/Layout'
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/page/login/index'),
    name: '专注食品标签审核 - 士海食标, 食品标签在线服务领导品牌',
    hidden: true
  },
  // {
  //   path: '/pdf',
  //   component: () => import('@/page/Pdf/index'),
  //   name: '士海食标 - 审核报告'
  // }, 
  {
    path: '/train',
    component: () => import('@/page/Train/index'),
    name: '士海食标 - 培训服务'
  },
  {
    path: '/getCoupons/:id',
    component: () => import('@/page/getCoupons/index'),
    name: '士海食标 - 领取优惠券'
  },
  {
    path: '/index/train',
    component: Layout,
    children: [
      {
        path: '/index/train',
        component: () => import('@/page/Train/index'),
        name: '士海食标 - 专题培训'
      },
    ]
  },
  {
    path: '/msg',
    component: Layout,
    meta: {
      title: 'index',
      icon: 'iconzhuye'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 消息中心',
        component: () => import('@/page/msg'),
        meta: {
          title: 'index',
          icon: 'iconzhuye',
          id: '1'
        }
      }
    ]
  },
  {
    path: '/index',
    component: Layout,
    meta: {
      title: 'index',
      icon: 'iconzhuye'
    },
    children: [
      {
        path: '/index',
        name: '士海食标 - 主页',
        component: () => import('@/page/index'),
        meta: {
          title: 'index',
          icon: 'iconzhuye',
          id: '1'
        }
      }
    ]
  },
  {
    path: '/food',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'company'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 订单管理',
        component: () => import('@/page/food/index'),
        meta: {
          title: 'company',
          icon: 'iconshipinbiaoqian',
          id: '3'
        }
      }
    ]
  },
  {
    path: '/food_details',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'company'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 订单详情',
        component: () => import('@/page/food_details'),
        meta: {
          title: 'company',
          icon: 'iconshipinbiaoqian',
          id: '3'
        }
      }
    ]
  },
  {
    path: '/money',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'company'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 账户管理',
        component: () => import('@/page/money'),
        meta: {
          title: 'company',
          icon: 'iconfeiyongguanli',
          id: '4'
        }
      }
    ]
  },
  {
    path: '/share',
    component: Layout,
    meta: {
      title: 'share',
      icon: 'share'
    },
    children: [
      {
        path: '/share',
        name: '士海食标 - 邀请朋友',
        component: () => import('@/page/share'),
        meta: {
          title: 'share',
          icon: 'iconicon-test',
          id: '0'
        }
      }
    ]
  },
  {
    path: '/couponsList',
    component: Layout,
    meta: {
      title: 'coupons',
      icon: 'coupons'
    },
    children: [
      {
        path: '/couponsList',
        name: '士海食标 - 优惠券中心',
        component: () => import('@/page/couponsList/index'),
        meta: {
          title: 'coupons',
          icon: 'iconfapiaoguanli',
          id: '6'
        }
      }
    ]
  },
  {
    path: '/bill',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'el-icon-printer'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 发票管理',
        component: () => import('@/page/bill'),
        meta: {
          title: 'company',
          icon: 'iconfapiaoguanli',
          id: '5'
        }
      }
    ]
  },
  {
    path: '/submit_tag',
    component: Layout,
    children: [
      {
        path: '/',
        name: '士海食标 - 提交标签',
        component: () => import('@/page/submit_tag'),
        meta: {
          title: 'company',
          icon: 'iconshipinbiaoqian',
          id: '3'
        }
      }
    ]
  },
  {
    path: '/usermod',
    component: Layout,
    children: [
      {
        path: '/',
        name: '士海食标 - 账户绑定',
        component: () => import('@/page/usermod'),
        meta: {
          title: 'company',
          icon: 'iconqiyexinxi',
          id: '1'
        }
      }
    ]
  },
  {
    path: '/add_money',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'company'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 账户充值',
        component: () => import('@/page/add_money'),
        meta: {
          title: 'company',
          icon: 'iconfeiyongguanli',
          id: '4'
        }
      }
    ]
  },
  {
    path: '/apply_firm',
    component: Layout,
    children: [
      {
        path: '/',
        name: '士海食标 - 企业认证',
        component: () => import('@/page/apply_firm'),
        meta: {
          title: 'company',
          icon: 'iconqiyexinxi',
          id: '2'
        }
      }
    ]
  },
  {
    path: '/company',
    component: Layout,
    meta: {
      title: 'company',
      icon: 'company'
    },
    children: [
      {
        path: '/',
        name: '士海食标 - 企业信息',
        component: () => import('@/page/company'),
        meta: {
          title: 'company',
          icon: 'iconqiyexinxi',
          id: '2'
        }
      }
    ]
  }
]

export default new Router({
  mode: 'history',
  routes: constantRouterMap
})

export const asyncRouterMap = [
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
